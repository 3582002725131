import React, { useState, useEffect } from 'react';
import { Container, Grid, Typography, Paper, CssBaseline, ThemeProvider, styled, Button, ButtonGroup, useMediaQuery, useTheme, Box, TextField, Avatar, MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { createTheme } from '@mui/material/styles';
import Header from '../components/Header';
import Footer from '../components/Footer';
import FileUpload from '../components/FileUpload';
import AppleIcon from '@mui/icons-material/Apple';
import GooglePlayIcon from '@mui/icons-material/PlayCircleOutline';
import HuaweiIcon from '../assets/images/HuaweiIcon.jsx';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';

import axios from 'axios';
// ... your image imports ...

const theme = createTheme({
  palette: {
    primary: {
      main: '#f3a83b', // Yellow color
    },
  },
  typography: {
    fontFamily: '"Poppins", "Roboto", sans-serif',
  },
});

// ... your styled components ...
const StyledSection = styled('section')(({ theme }) => ({
  padding: theme.spacing(4),
  backgroundColor: '#fff',
  marginBottom: theme.spacing(2),
}));

const ImageColumn = styled('div')({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  marginBottom: theme.spacing(2), // Add margin to separate rows on mobile
});

const TextColumn = styled('div')({
  display: 'flex',
  flexDirection: 'column', // Display text in a column layout
  // alignItems: 'center',
  // justifyContent: 'center',
});

const CustomButton = ({ startIcon, label, link }) => (
  <ThemeProvider theme={theme}>
    <a href={link} target="_blank" rel="noopener noreferrer" className="custom-button-link" style={{ width: '100%', margin: '5px' }}>
      <Button
        variant="contained"
        sx={{
          width: '100%',
          color: 'white',
          flexDirection: 'row',
          justifyContent: 'flex-start',
          paddingLeft: '1.5em',
          mb: { xs: 1, sm: 0 },
          borderRadius: '10px',
          textTransform: 'none',
        }}
        startIcon={startIcon}
      >
        <span style={{ fontFamily: 'roboto' }}>{label}</span>
      </Button>
    </a>
  </ThemeProvider>
);

const RegistrationForm = () => {
  //Operator Identification
  const location = useLocation();
  const path = location.pathname;

  //Loading 
  const [loading, setLoading] = useState(false); // To track form submission status
  const [uploading, setUploading] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  // Personal information
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState(''); // 10 digits

  
  //Spark Fields
  const [operatorId, setOperatorId] = useState(''); // From URL

  


  const cleanForm = () => {
    setName('');
    setPhoneNumber('');  
  };

  


  

  const handleSubmit = async (event) => {
    event.preventDefault();

   

    setLoading(true); // Start loading

    try {
      const formData = new FormData();
  
      // Append text data to the FormData object
      formData.append('name', name);
      formData.append('phoneNumber', phoneNumber.toString());

      formData.append('operatorId', operatorId);
     

      const config = {
        onUploadProgress: (progressEvent) => {
          const progress = Math.round((progressEvent.loaded * 100) / progressEvent.total);
          setUploadProgress(progress);
        },
      };
      setUploading(true); // Start uploading
      // Make a POST request to the backend
      const response = await axios.post('https://formsapi.sparkride.sa/api/forms', formData, {
        headers: {
          'Content-Type': 'multipart/form-data', // Important for file uploads
        },
      });
      setUploading(false); // Finish uploading
      setLoading(false); // Finish loading
      console.log('Form submitted successfully:', response.data);
      window.alert('Form submitted successfully!')
      cleanForm();
    } catch (error) {
      console.error('Error submitting form:', error);
      // Handle error and show error message to user
      window.alert('Error submitting form!');
      setLoading(false); // Finish loading
      //cleanForm();
    }
  };

  useEffect(() => {
    if (loading) {
      document.body.style.cursor = 'wait';
    } else {
      document.body.style.cursor = 'default';
    }
  }, [loading]);


  useEffect(() => {
    // Set the operatorId based on the URL path
    if (path === '/c1' || path === '/C1') {
      setOperatorId('C1');
    } else if (path === '/c2' || path === '/C2') {
      setOperatorId('C2');
    } else if (path === '/c3' || path === '/C3') {
      setOperatorId('C3');
    } else if (path === '/') {
      setOperatorId('SparkRide');
    } else {
      setOperatorId('Other');
    }
    console.log(operatorId)
  }, [path]);


  const [isValid, setIsValid] = useState(true); // Track if the input is valid English text
  function isEnglishText(text) {
    // Regular expression to match only English letters and spaces
    const englishRegex = /^[A-Za-z\s]*$/;
    return englishRegex.test(text);
  }


  return (
    <StyledSection sx={{mb:30}}>
      <Grid container spacing={2} alignItems="center" justifyContent="center" sx={{ mt: 3 }}>
        <Grid item xs={12} sm={12} md={12}>
          <TextColumn>
            <Typography variant="h4" fontFamily="poppins" align="center" sx={{ mb: 5 }}>
              Registration Form
            </Typography>
            <ImageColumn>
              <label htmlFor="profilePictureInput" style={{ cursor: 'pointer', display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Avatar
                  alt="Profile"
                  src=""
                  sx={{ width: 75, height: 75, marginX: 'auto' }} 
                />
              </label>
            </ImageColumn>

            <Box
              component="form"
              sx={{
                display: 'flex',
                flexDirection: 'column',
                '& .MuiTextField-root': { m: 1 },
                alignItems: 'center', // Center the form elements horizontally
              }}
              autoComplete="off"
              onSubmit={handleSubmit}
            >
              {/* <TextField
                  required
                  label="First Name & Last Name"
                  InputLabelProps={{ shrink: true }} // Keeps the label "floating" when there's input
                  placeholder="First & Last Name"
                  variant="outlined"
                  value={name}
                  onChange={(event) => setName(event.target.value.toUpperCase())}
                  inputProps={{
                  style: {
                    textAlign: 'center',
                  },
                }}
                />
                
                */}

<TextField
                required
                label="First Name & Last Name"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                placeholder="First & Last Name"
                value={name}
                onChange={(event) => {
                  const inputValue = event.target.value;
                  if (isEnglishText(inputValue)) {
                    setName(inputValue.toUpperCase());
                    setIsValid(true); // Set to true if input is valid
                  } else {
                    setIsValid(false); // Set to false if input is not valid
                  }
                }}
                inputProps={{
                  style: {
                    textAlign: 'center',
                  },
                }}
                error={!isValid} // Show error state if input is not valid
                helperText={!isValid ? "Only English text is allowed" : ""} // Error message
              />
                <TextField
                  required
                  label="Phone Number"
                  variant="outlined"
                  InputLabelProps={{ shrink: true}} // Keeps the label "floating" when there's input
                  placeholder="05XXXXXXXX"
                  
                  value={phoneNumber}
                  onChange={(event) => {
                    const inputValue = event.target.value.replace(/\D/g, ""); // Remove non-digit characters
                    const formattedValue = inputValue.slice(0, 10); // Clear if not starting with "05"
                    setPhoneNumber(formattedValue);
                  }}
                  inputProps={{
                    maxLength: 10, // Set the maximum length
                    style: {
                      textAlign: 'center',
                    },
                  }}
                />

              <Button type="submit" variant="outlined" sx={{ color: '#000', borderColor: '#000', marginTop: '1rem' }}>
                {loading ? 'Submitting...' : 'Register'}
              </Button>
            </Box>
          </TextColumn>
        </Grid>
      </Grid>
    </StyledSection>
  );
};

const Home = () => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Header />

      <Container>
        <RegistrationForm />
      </Container>

      <Footer />
    </ThemeProvider>
  );
};

export default Home;
